<template>
  <router-link
    :to="link"
    class="hover:bg-gray-50 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-700 hover:border-primary-700 border sm:cursor-pointer shadow-md border-gray-100 bg-white rounded-md p-3 flex items-center justify-between"
  >
    <div>
      <h3 class="mb-2">{{ title }}</h3>
      <p class="text-gray-500 font-light text-sm mb-2">{{ content }}</p>
      <p class="text-gray-400 font-light text-xs">
        {{ givenDate }}
      </p>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 text-gray-800 dark:text-white"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
      />
    </svg>
  </router-link>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardCard",
  props: {
    title: {
      type: String
    },
    content: {
      type: [String, Number]
    },
    link: {
      type: String,
      default: "/"
    },
    date: {
      type: [Object, String],
      default() {
        return moment("18-08-1964", "DD-MM-YYYY");
      }
    }
  },
  computed: {
    givenDate() {
      return moment(this.date).format("DD.MM.YYYY");
    }
  }
};
</script>

<style></style>
