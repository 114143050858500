import Vue from "vue";
import Vuex from "vuex";
import workload from "./workload";
import artikel from "./artikel";
import anfragen from "./anfragen";
import belegpositionen from "./belegpositionen";

Vue.use(Vuex);

const moduleA = {
  state: {
    currItem: {},
    kunden: [],
    user: {},
    auftraege: []
  },
  getters: {
    getAllCustomers: state => {
      return state.kunden;
    },
    getAllAuftraege: state => {
      return state.auftraege;
    }
  },
  mutations: {
    updateAuftraege(state, n) {
      state.auftraege = n;
    },
    setUser(state, n) {
      state.user = n;
    },
    updateKunden(state, n) {
      state.kunden = n;
    },
    unsetUser(state) {
      state.user = null;
    },
    setEnv(state, n) {
      state.env = n;
    }
  },
  actions: {
    updateAuftraege({ commit }) {
      commit("updateAuftraege");
    },
    setUser({ commit }) {
      commit("setUser");
    },
    updateKunden({ commit }) {
      commit("updateKunden");
    },
    unsetUser({ commit }) {
      commit("unsetUser");
    }
  }
};

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    main: moduleA,
    workload: workload,
    artikel: artikel,
    anfragen: anfragen,
    belegpositionen: belegpositionen
  }
});

export default store;
