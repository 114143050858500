<template>
  <div class="flex flex-col flex-100">
    <h2>Dashboard Übersicht</h2>

    <div
      class="flex flex-wrap sm:gap-20 gap-4 items-center justify-center flex-1"
    >
      <dashboard-shell title="Kunden" link="/kunden" />
      <dashboard-shell title="Lieferanten" link="/lieferanten" />
      <div @click="!setActive('verkauf')">
        <dashboard-shell title="Verkauf" link="#" />
      </div>
      <div @click="!setActive('einkauf')">
        <dashboard-shell title="Einkauf" link="#" />
      </div>
      <div @click="!setActive('buchhaltung')">
        <dashboard-shell title="Buchhaltung" link="#" />
      </div>
    </div>

    <div
      class="flex flex-wrap sm:gap-20 gap-4 items-center justify-center flex-1 mt-20"
    >
      <div
        v-if="active === 'verkauf'"
        class="flex sm:gap-20 gap-4 flex-wrap items-center justify-center"
      >
        <dashboard-shell title="Aufträge" />
        <dashboard-shell title="Angebote" />
        <dashboard-shell title="Lieferscheine" />
        <dashboard-shell title="Rechnungen" />
        <dashboard-shell title="Rahmenaufträge" />
      </div>
      <div
        v-if="active === 'einkauf'"
        class="flex sm:gap-20 gap-4 flex-wrap items-center justify-center"
      >
        <dashboard-shell title="Anfragen" />
        <dashboard-shell title="Bestellungen" />
        <dashboard-shell title="Wareneingang" />
        <dashboard-shell title="Eingangsrechnungen" />
      </div>
      <div
        v-if="active === 'buchhaltung'"
        class="flex sm:gap-20 gap-4 flex-wrap items-center justify-center"
      >
        <dashboard-shell title="Aufträge" />
        <dashboard-shell title="Angebote" />
        <dashboard-shell title="Lieferscheine" />
        <dashboard-shell title="Rechnungen" />
        <dashboard-shell title="Rahmenaufträge" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardShell from "@/components/dashboard/DashboardShell";
export default {
  name: "DashboardUebersicht",
  components: { DashboardShell },
  data() {
    return {
      active: ""
    };
  },
  methods: {
    setActive(obj) {
      console.log(obj);
      this.active = obj;
    }
  }
};
</script>
