<template>
  <header
    class="border-b border-gray-200 p-2 sm:p-4 grid sm:grid-cols-2 sm:gap-8 gap-4 dark:bg-gray-800 dark:border-gray-900 print:hidden"
  >
    <h1 class="text-2xl md:text-4xl font-extralight dark:text-white">
      <span v-if="user">{{ greeting }}, </span>
      <span v-else>Bitte anmelden</span>
      <span class="font-medium" v-if="user">{{
        user.user_metadata.firstname
      }}</span>
    </h1>
    <label class="flex-1" v-if="user">
      <input
        type="search"
        placeholder="🔎  Hier kannst du alles suchen..."
        class="border-none text-sm md:h-full rounded-md focus:outline-none focus:border-green-800 dark:bg-gray-700 dark:text-white"
      />
    </label>
  </header>
</template>

<script>
import moment from "moment";

export default {
  computed: {
    currentTime() {
      moment.locale("de_DE");
      return moment().format("LT");
    },
    greeting() {
      return this.currentTime > "00:00" && this.currentTime < "12:00"
        ? "Guten Morgen"
        : this.currentTime > "12:00" && this.currentTime < "18:00"
        ? "Guten Tag"
        : "Guten Abend";
    },
    user() {
      return this.$store.state.main.user;
    }
  }
};
</script>

<style lang="scss" scoped>
input:focus::placeholder {
  color: transparent;
}
</style>
