<template>
  <div>
    <dashboard-element>
      <dashboard-head title="Aufträge" link="/auftragsuebersicht" />
      <dashboard-table class="p-3">
        <div v-if="$apollo.queries.Belege.loading">
          Lade Daten...
        </div>
        <dashboard-card
          v-else
          v-for="auftrag in Belege"
          :key="auftrag.BelegNr"
          :title="`${auftrag.Belegart} ${auftrag.BelegNr}`"
          :content="`${splitFirma(auftrag.Firma)}`"
          :link="`/auftrag/${splitJoin(auftrag.BelegNr)}`"
          :date="auftrag.Datum"
        />
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DashboardElement from "./dashboard/DashboardElement.vue";
import DashboardHead from "./dashboard/DashboardHead.vue";
import DashboardCard from "./dashboard/DashboardCard.vue";
import DashboardTable from "./dashboard/DashboardTable.vue";

export const GET_MY_DATA = gql`
  query Belege {
    Belege(
      where: { Belegart: { _eq: "AB" } }
      limit: 5
      order_by: { Datum: desc }
    ) {
      Belegart
      BelegNr
      Firma
      Datum
    }
  }
`;

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardCard,
    DashboardTable
  },
  methods: {
    splitJoin(str) {
      return str.split("/").join("");
    },
    splitFirma(str) {
      return str.split("\n")[0];
    }
  },
  apollo: {
    Belege: {
      query: GET_MY_DATA,
      error(error) {
        this.error = JSON.stringify(error.message);
      }
    }
  }
};
</script>

<style></style>
