import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { config } from "dotenv";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "./assets/tailwind.css";

import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";

Vue.use(VueApollo);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_DSN_SENTRY,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "gries.com", /^\//]
      })
    ],
    tracesSampleRate: 1.0
  });
}

config();

Vue.config.productionTip = false;

// Create an http link:
const link = new HttpLink({
  uri: "https://anfragecenter.hasura.app/v1/graphql",
  fetch,
  headers: {
    "x-hasura-admin-secret":
      window.localStorage.getItem("apollo-token") ||
      "vqA0S1vcnnrSqDla26CJBmXY66bqq25074A4xJ3BnRv5oGoVY8SDqn3qKB6ultwv"
  }
});
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true
  })
});

Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.eventSetDrag = function() {
      el.setAttribute("data-dragging", "yes");
    };
    el.eventClearDrag = function() {
      el.removeAttribute("data-dragging");
    };
    el.eventOnClick = function(event) {
      const dragging = el.getAttribute("data-dragging");

      if (!(el === event.target || el.contains(event.target)) && !dragging) {
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener("touchstart", el.eventClearDrag);
    document.addEventListener("touchmove", el.eventSetDrag);
    document.addEventListener("click", el.eventOnClick);
    document.addEventListener("touchend", el.eventOnClick);
  },
  unbind: function(el) {
    document.removeEventListener("touchstart", el.eventClearDrag);
    document.removeEventListener("touchmove", el.eventSetDrag);
    document.removeEventListener("click", el.eventOnClick);
    document.removeEventListener("touchend", el.eventOnClick);
    el.removeAttribute("data-dragging");
  }
});

const apolloProvider = new VueApollo({
  defaultClient: client
});

new Vue({
  store,
  router,
  apolloProvider,
  render: h => h(App)
}).$mount("#app");
