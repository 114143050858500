const artikel = {
  state: () => ({
    products: []
  }),
  getters: {
    getProductById: state => id => {
      return state.products.find(product => product.artikelcode === id);
    },
    getAllProducts: state => {
      return state.products;
    }
  },
  mutations: {
    updateArtikel(state, n) {
      state.products = n;
    }
  },
  actions: {
    updateArtikel({ commit }) {
      commit("updateArtikel");
    }
  }
};

export default artikel;
