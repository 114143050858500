const workload = {
  state: () => ({
    pa: []
  }),
  getters: {
    getPaById: state => id => {
      return state.pa.find(pa => pa.id === id);
    },
    getPaByArbeitsgangId: state => id => {
      return state.pa.find(pa => pa.ag.id === id);
    },
    getPaByKw: state => kw => {
      return state.pa.filter(pa => pa.kw === kw);
    }
  },
  mutations: {
    updatePa(state, n) {
      state.pa = n;
    }
  },
  actions: {
    updatePa({ commit }) {
      commit("updatePa");
    }
  }
};

export default workload;
