const belegpositionen = {
  state: () => ({
    belegpositionen: []
  }),
  getters: {
    getBelegPositionById: state => id => {
      return state.belegpositionen.find(
        belegposition => belegposition.artikelcode === id
      );
    },
    getAllProductsFromBelege: state => id => {
      return state.belegpositionen.filter(pos => pos.artikelcode.includes(id));
    },
    getBelegeOnlyAB: state => {
      return state.belegpositionen.filter(
        belegpositione => belegpositione.belegart === "AB"
      );
    },
    getBelegeFrom2020: state => {
      return state.belegpositionen.filter(belegpositione =>
        belegpositione.belegnr.includes("AB 20")
      );
    },
    getFilteredByArtikelcode: state => id => {
      return state.belegpositionen
        .filter(belegpositione => belegpositione.belegnr.includes("AB 21"))
        .filter(pos => pos.artikelcode.includes(id));
    },
    getRankedArtikel: state => {
      return state.belegpositionen
        .filter(belegpositione => belegpositione.belegnr.includes("AB 21"))
        .sort((a, b) => a - b);
    },
    getBelegeFrom2021: state => {
      return state.belegpositionen.filter(belegpositione =>
        belegpositione.belegnr.includes("AB 21")
      );
    },
    getBelegeBezeichnung: state => id => {
      return state.belegpositionen
        .filter(belegpositione => belegpositione.belegnr.includes("AB 21"))
        .filter(pos => pos.bezeichnung.includes(id));
    },
    getAllBelegPositionen: state => {
      return state.belegpositionen;
    }
  },
  mutations: {
    updateBelegPositionen(state, n) {
      state.belegpositionen = n;
    }
  },
  actions: {
    updateBelegPositionen({ commit }) {
      commit("updateBelegPosition");
    }
  }
};

export default belegpositionen;
