import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import RequestContent from "../components/RequestContent.vue";
import DashboardOverview from "@/views/DashboardOverview";
import { supabase } from "@/plugins/supabase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/anfrage/:id(\\d+)",
    name: "Request Details",
    component: RequestContent
  },
  {
    path: "/dashover",
    name: "Dashboard Overview",
    component: DashboardOverview
  },
  {
    path: "/auftrag/neu",
    name: "Neuer Auftrag",
    component: () =>
      import(
        /* webpackChunkName: "auftrag_neu" */ "../views/auftraege/AuftragNeu.vue"
      )
  },
  {
    path: "/auftrag/:id",
    name: "Production Order",
    component: () =>
      import(/* webpackChunkName: "auftrag" */ "../views/auftraege/Auftrag.vue")
  },
  {
    path: "/lieferscheine",
    name: "Lieferscheine",
    component: () =>
      import(
        /* webpackChunkName: "lieferscheine" */ "../views/lieferscheine/LieferscheinUebersicht.vue"
      )
  },
  {
    path: "/pa",
    name: "Produktionsauftraege",
    component: () =>
      import(
        /* webpackChunkName: "produktionsauftraege" */ "../views/Produktionsauftraege.vue"
      )
  },
  {
    path: "/pa-import",
    name: "ProduktionsauftragsImport",
    component: () =>
      import(
        /* webpackChunkName: "produktionsauftragimport" */ "../views/imports/ProduktionsauftragsImport.vue"
      )
  },
  {
    path: "/belege-import",
    name: "BelegeImport",
    component: () =>
      import(
        /* webpackChunkName: "belegeimport" */ "../views/imports/BelegeImport.vue"
      )
  },
  {
    path: "/kunden-import",
    name: "KundenImport",
    component: () =>
      import(
        /* webpackChunkName: "kundenimport" */ "../views/imports/KundenImport.vue"
      )
  },
  {
    path: "/artikel-import",
    name: "ArtikelImport",
    component: () =>
      import(
        /* webpackChunkName: "kundenimport" */ "../views/imports/ArtikelImport.vue"
      )
  },
  {
    path: "/auswertungen",
    name: "Auswertungen",
    component: () =>
      import(
        /* webpackChunkName: "auswertungen" */ "../views/auswertungen/Auswertung.vue"
      )
  },
  {
    path: "/arbeitsgang-import",
    name: "ArbeitsgangImport",
    component: () =>
      import(
        /* webpackChunkName: "arbeitsgangimport" */ "../views/imports/ArbeitsgaengeImport.vue"
      )
  },
  {
    path: "/pa/:id",
    name: "Produktionsauftraege Detailansicht",
    component: () =>
      import(
        /* webpackChunkName: "produktionsauftragsdetails" */ "../views/ProduktionsauftraegeDetails.vue"
      )
  },
  {
    path: "/artikel",
    name: "Artikel Übersicht",
    component: () =>
      import(
        /* webpackChunkName: "artikeluebersicht" */ "../views/artikel/ArtikelUebersicht.vue"
      )
  },
  {
    path: "/artikel/:id",
    name: "Artikel Detailansicht",
    component: () =>
      import(
        /* webpackChunkName: "artikeldetails" */ "../views/artikel/Artikel.vue"
      )
  },
  {
    path: "/pps",
    name: "Produktionsplanung",
    component: () =>
      import(
        /* webpackChunkName: "produktionsplanung" */ "../views/ProduktionsPlanung.vue"
      )
  },
  {
    path: "/arbeitsplaetze",
    name: "Arbeitsplaetze",
    component: () =>
      import(
        /* webpackChunkName: "arbeitsplaetze" */ "../views/Arbeitsplaetze.vue"
      )
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/admin/Index.vue")
  },
  {
    path: "/pps-import",
    name: "Auftrags Import",
    component: () =>
      import(
        /* webpackChunkName: "auftragsimport" */ "../views/auftraege/AuftragsImport.vue"
      )
  },
  {
    path: "/anfragen",
    name: "Anfragen Übersicht",
    component: () =>
      import(/* webpackChunkName: "anfragen" */ "../views/Requests.vue")
  },
  {
    path: "/pps-bearbeitungszeiten",
    name: "Bearbeitungszeiten",
    component: () =>
      import(
        /* webpackChunkName: "bearbeitungszeiten" */ "../views/BearbeitungsZeiten"
      )
  },
  {
    path: "/pps-arbeitsgaenge",
    name: "Arbeitsgänge",
    component: () =>
      import(/* webpackChunkName: "arbeitsgaenge" */ "../views/Arbeitsgaenge")
  },
  {
    path: "/",
    name: "Index",
    redirect: { name: "Dashboard" }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  // {
  //   path: "/:catchAll(.*)",
  //   name: "NotFound",
  //   component: Dashboard
  // },
  {
    path: "/produkte",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products")
  },
  {
    path: "/notizbuch",
    name: "TextEditor",
    component: () =>
      import(/* webpackChunkName: "texteditor" */ "../views/TextEditor.vue")
  },
  {
    path: "/medien",
    name: "Media",
    component: () => import(/* webpackChunkName: "media" */ "../views/Media")
  },
  {
    path: "/kunden",
    name: "Customers",
    component: () =>
      import(/* webpackChunkName: "customers" */ "../views/Customers")
  },
  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/Support")
  },
  {
    path: "/calculator",
    name: "Calculator",
    component: () =>
      import(/* webpackChunkName: "calculator" */ "../views/Calculator")
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings")
  },
  {
    path: "/auftraege",
    name: "Auftragsübersicht",
    component: () =>
      import(
        /* webpackChunkName: "auftraege" */ "../views/auftraege/AuftragsUebersicht"
      )
  }
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !supabase.auth.user()) next({ name: "Login" });
  else next();
});

export default router;
