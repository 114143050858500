<template>
  <form @submit.prevent>
    <h2>Anhänge:</h2>
    <h2 v-if="!files.length">Keine Dateianhänge vorhanden</h2>
    <ul class="pl-0">
      <li
        class="bg-gray-100 text-gray-900 rounded px-1 mb-1 flex justify-between text-sm"
        v-bind:key="file.id"
        v-for="file in files"
      >
        <a :href="getFileUrl(file.name)" target="_blank">{{ file.name }}</a>
        <button @click="removeFile(file.name)" style="cursor: pointer">
          x
        </button>
      </li>
    </ul>

    <div class="flex flex-col flex-grow-0">
      <label
        >Datei hochladen
        <input
          type="file"
          name="upload"
          id="upload"
          @change="onFileChange"
          required
        />
      </label>
      <div class="inline-block">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-1"
          v-if="btnActive"
          @click="uploadFile"
        >
          Datei speichern
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { supabase } from "@/plugins/supabase";

export default {
  name: "FileUpload",
  data() {
    return {
      files: 0,
      btnActive: false
    };
  },
  created() {
    this.listFiles();
  },
  methods: {
    async removeFile(filename) {
      await supabase.storage
        .from("requests")
        .remove([`${this.requestId}/${filename}`]);
      this.listFiles();
    },
    onFileChange(e) {
      !e.target.files.length
        ? (this.btnActive = false)
        : (this.btnActive = true);
    },
    async listFiles() {
      await supabase.storage
        .from("requests")
        .list(`${this.requestId}`)
        .then(res => {
          this.files = res.data;
        });
    },
    getFileUrl(filename) {
      const { publicURL } = supabase.storage
        .from("requests")
        .getPublicUrl(`${this.requestId}/${filename}`);

      return publicURL;
    },
    async uploadFile() {
      const file = document.querySelector("#upload").files[0];
      const currDateTime = new Date().getTime();

      await supabase.storage
        .from("requests")
        .upload(`${this.requestId}/${currDateTime}_${file.name}`, file, {
          cacheControl: 3600,
          upsert: false
        });

      this.listFiles();
      document.querySelector("#upload").value = "";
      this.btnActive = false;
    }
  },
  props: {
    requestId: {
      type: Number,
      required: true,
      default: 1
    }
  }
};
</script>
