<template>
  <div
    class="body bg-gray-200 flex flex-col gap-2 rounded-b-md dark:bg-gray-600"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DashboardTable"
};
</script>

<style></style>
