<template>
  <div
    id="app"
    class="flex flex-col-reverse sm:flex-row dark:bg-gray-700 min-h-screen bg-background"
  >
    <base-navigation v-if="$store.state.main.user" />
    <div class="flex-auto">
      <base-header />
      <main class="p-2 md:p-4">
        <router-view @rehydrate="loadInitialData()" />
      </main>
    </div>
  </div>
</template>

<script>
import BaseNavigation from "./components/BaseNavigation.vue";
import BaseHeader from "./components/BaseHeader.vue";
import { supabase } from "./plugins/supabase";
import axios from "axios";

export default {
  name: "MainAppComponent",
  components: {
    BaseNavigation,
    BaseHeader
  },
  created() {
    this.loadUser();
    this.loadInitialData();
    this.loadSubscribers();

    this.$store.commit("setEnv", process.env.NODE_ENV);
  },
  methods: {
    async loadUser() {
      await this.$store.commit("setUser", supabase.auth.user());
    },
    async loadInitialData() {
      this.loadArtikel();
      this.loadKunden();
      this.loadAuftraege();
      this.loadAnfragen();
      this.loadPa();
      this.loadBelegPositionen();
    },
    async loadArtikel() {
      await supabase
        .from("artikel")
        .select("*", { count: "exact" })
        .then(res => {
          this.$store.commit("updateArtikel", res.data);
        });
    },
    async loadBelegPositionen() {
      await supabase
        .from("belegpositionen")
        .select("*", { count: "exact" })
        .then(res => {
          this.$store.commit("updateBelegPositionen", res.data);
        });
    },
    async loadPa() {
      await supabase
        .from("produktionsauftraege")
        .select("*", { count: "exact" })
        .then(res => {
          this.$store.commit("updatePa", res.data);
        });
    },
    async loadKunden() {
      await supabase
        .from("kunden")
        .select("*", { count: "exact" })
        .then(res => {
          this.$store.commit("updateKunden", res.data);
        });
    },
    async loadAuftraege() {
      await supabase
        .from("auftraege")
        .select("*", { count: "exact" })
        .then(res => {
          this.$store.commit("updateAuftraege", res.data);
        });
    },
    async loadAnfragen() {
      await supabase
        .from("anfrage")
        .select("*", { count: "exact" })
        .then(res => {
          this.$store.commit("updateAnfragen", res.data);
        });
    },
    loadSubscribers() {
      supabase
        .from("anfrage")
        .on("*", payload => {
          console.log("Change received!", payload);
          this.loadAnfragen();
        })
        .subscribe();

      supabase
        .from("anfrage")
        .on("INSERT", payload => {
          console.log(payload);
          axios.post(
            `https://api.telegram.org/bot5073969887:AAFV3F5ONR0Cl2nXnPTz_y7a_2nJTYqtLqU/sendMessage?chat_id=-1001222752842&text=Neue Anfrage:%0Ahttps://gries.app/anfrage/${payload.new.id}&disable_notification=true&disable_web_page_preview=true`
          );
        })
        .subscribe();

      supabase
        .from("kunden")
        .on("*", payload => {
          console.log("Change received!", payload);
          this.loadKunden();
        })
        .subscribe();

      supabase
        .from("produktionsauftraege")
        .on("*", payload => {
          console.log("Change received!", payload);
          this.loadPa();
        })
        .subscribe();

      supabase
        .from("artikel")
        .on("*", payload => {
          console.log("Change received!", payload);
          this.loadArtikel();
        })
        .subscribe();
    }
  }
};
</script>
