<template>
  <div
    class="head dark:bg-gray-800 bg-white px-4 py-2 flex rounded-t-md justify-between items-center relative"
  >
    <div class="flex gap-2 items-center">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 text-gray-800 dark:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
      </div>
      <h2 class="font-medium text-lg text-gray-800 dark:text-white">
        {{ title }}
      </h2>
    </div>
    <router-link :to="link">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4 text-gray-800 dark:text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "DashboardHead",
  props: {
    title: {
      type: String
    },
    link: {
      type: String,
      default: "#"
    }
  }
};
</script>

<style></style>
