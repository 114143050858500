const anfragen = {
  state: () => ({
    requests: []
  }),
  getters: {
    getRequestById: state => id => {
      return state.requests.find(request => request.id === id);
    },
    getAllRequests: state => {
      return state.requests;
    }
  },
  mutations: {
    updateAnfragen(state, n) {
      state.requests = n;
    }
  },
  actions: {
    updateAnfragen({ commit }) {
      commit("updateAnfragen");
    }
  }
};

export default anfragen;
