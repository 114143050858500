<template>
  <router-link :to="link" @click="$emit('wasClicked', open)">
    <div
      class="dark:bg-gray-800 dark:text-white bg-white rounded-md h-40 w-40 shadow-md grid place-content-center"
    >
      <h3>{{ title }}</h3>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "DashboardShell",
  props: {
    title: {
      type: String
    },
    link: {
      type: String,
      default: "/"
    },
    open: {
      type: String
    }
  }
};
</script>

<style scoped></style>
