<template>
  <div class="pb-4 dark:bg-gray-900 request-content">
    <div class="flex items-center justify-between mb-2 bg-white p-2">
      <button @click="goBack">
        <icon-left-arrow />
      </button>
      <h2>Anfrage: {{ $route.params.id }}</h2>
    </div>

    <div class="p-2 bg-gray-200">
      <div
        class="font-bold sm:pl-2 border-b-2 border-gray-800 mb-4 text-center bg-white"
      >
        {{ formData.form }} {{ formData.durchmesser }}-{{
          formData.belagbreite
        }}-{{ formData.belaghoehe }}-{{ formData.gesamthoehe }}-{{
          formData.bohrung
        }}
        {{ formData.korngroesse }} {{ formData.korn }}
        {{ formData.konzentration }}
        {{ formData.bindung }}
      </div>

      <form @submit.prevent="updateRequest">
        <div class="grid grid-cols-2 gap-2">
          <label>
            Form
            <select name="form" id="form" v-model="formData.form">
              <option value=""></option>
              <option
                v-for="form in abrasiveData.formen"
                v-bind:key="form.id"
                v-bind:value="form.name"
                >{{ form.name }}</option
              >
            </select>
          </label>
          <label>
            Durchmesser
            <select
              name="durchmesser"
              id="durchmesser"
              v-model="formData.durchmesser"
            >
              <option value=""></option>
              <option
                v-for="durchmesser in abrasiveData.durchmesser"
                v-bind:key="durchmesser.id"
                >{{ durchmesser.name }}</option
              >
            </select>
          </label>
          <label>
            Belagbreite
            <input
              type="text"
              name="belagbreite"
              v-model="formData.belagbreite"
            />
          </label>
          <label>
            Belaghöhe
            <input
              type="text"
              name="belaghoehe"
              v-model="formData.belaghoehe"
            />
          </label>
          <label v-if="belagwinkel">
            Belagwinkel
            <input
              type="text"
              name="belaghoehe"
              v-model="formData.belagwinkel"
            />
          </label>
          <label>
            Gesamthöhe
            <input
              type="text"
              name="gesamthoehe"
              v-model="formData.gesamthoehe"
            />
          </label>
          <label>
            Bohrung
            <input type="text" name="bohrung" v-model="formData.bohrung" />
          </label>
          <label>
            Korngröße
            <select
              name="korngroesse"
              id="korngroesse"
              v-model="formData.korngroesse"
            >
              <option value=""></option>
              <option
                v-for="korngroesse in abrasiveData.korngroesse"
                v-bind:key="korngroesse.id"
                :value="korngroesse.name"
                >{{ korngroesse.name }}</option
              >
            </select>
          </label>
          <label>
            Korn
            <select name="korn" id="korn" v-model="formData.korn">
              <option value=""></option>
              <option
                v-for="korn in abrasiveData.korn"
                v-bind:key="korn.id"
                :value="korn.bezeichnung"
                >{{ korn.bezeichnung }}</option
              >
            </select>
          </label>
          <label>
            Konzentration
            <select
              name="konzentration"
              id="konzentration"
              v-model="formData.konzentration"
            >
              <option value=""></option>
              <option
                v-for="konzentration in abrasiveData.konzentration"
                v-bind:key="konzentration.id"
                :value="konzentration.name"
                >{{ konzentration.name }}</option
              >
            </select>
          </label>
          <label>
            Bindung
            <select name="bindung" id="bindung" v-model="formData.bindung">
              <option value=""></option>
              <option
                v-for="bindung in abrasiveData.bindung"
                v-bind:key="bindung.id"
                :value="bindung.name"
                >{{ bindung.name }}</option
              >
            </select>
          </label>
        </div>
        <button
          type="submit"
          class="w-full bg-primary-700 text-white ring-1 font-bold uppercase py-2 px-4 rounded mt-4"
        >
          Speichern
        </button>
      </form>
      <div class="mb-4 mt-4">
        <file-upload :request-id="request.id" />
      </div>

      <div class="mt-2" v-if="request.anwendungen">
        <span
          class="bg-gray-100 text-gray-800 text-sm rounded px-1.5 py-1 mr-1"
          v-for="anwendung in request.anwendungen"
          :key="anwendung"
        >
          {{ anwendung }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IconLeftArrow from "../components/icons/IconLeftArrow";
import FileUpload from "../components/FileUpload";
import { supabase } from "../plugins/supabase";

export default {
  name: "RequestContent",
  components: {
    IconLeftArrow,
    FileUpload
  },
  data() {
    return {
      savedChanges: [],
      abrasiveData: {}
    };
  },
  created() {
    this.fetchAbrasiveData();
    this.loadData();
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    async updateRequest() {
      await supabase
        .from("anfrage")
        .upsert({ id: this.$route.params.id, ...this.formData });
    },
    async loadData() {
      await supabase
        .from("anfrage")
        .select()
        .eq("id", this.$route.params.id)
        .then(res => (this.$store.state.main.currItem = res.data[0]))
        .then(data => console.log(data));
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    fetchAbrasiveData() {
      supabase
        .from("form")
        .select()
        .eq("active", "true")
        .order("prio")
        .then(res => {
          this.abrasiveData.formen = res.data;
        });

      supabase
        .from("durchmesser")
        .select()
        .eq("active", "true")
        .order("durchmesser", { ascending: true })
        .then(res => {
          this.abrasiveData.durchmesser = res.data;
        });

      supabase
        .from("korngroesse")
        .select()
        .eq("active", "true")
        .order("name", { ascending: true })
        .then(res => {
          this.abrasiveData.korngroesse = res.data;
        });

      supabase
        .from("korn")
        .select()
        .eq("active", "true")
        .order("bezeichnung", { ascending: true })
        .then(res => {
          this.abrasiveData.korn = res.data;
        });

      supabase
        .from("konzentration")
        .select()
        .order("name", { ascending: true })
        .then(res => {
          this.abrasiveData.konzentration = res.data;
        });

      supabase
        .from("bindung")
        .select()
        .eq("active", "true")
        .order("name", { ascending: true })
        .then(res => {
          this.abrasiveData.bindung = res.data;
        });

      supabase
        .from("kunden")
        .select()
        .then(res => {
          this.kunden = res.data;
        });
    }
  },
  computed: {
    belagwinkel() {
      return true;
    },
    request() {
      return this.$store.state.main.currItem;
    },
    formData() {
      return {
        form: this.request.form,
        durchmesser: this.request.durchmesser,
        belagbreite: this.request.belagbreite,
        belaghoehe: this.request.belaghoehe,
        gesamthoehe: this.request.gesamthoehe,
        bohrung: this.request.bohrung,
        korngroesse: this.request.korngroesse,
        korn: this.request.korn,
        konzentration: this.request.konzentration,
        bindung: this.request.bindung,
        belagwinkel: this.request.belagwinkel
      };
    }
  }
};
</script>
