<template>
  <div>
    <h2 class="mb-4">Dashboard</h2>

    <div
      class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8"
    >
      <query-file />
      <dashboard-element>
        <dashboard-head title="Anfragen" link="/anfragen" />
        <dashboard-table class="p-3">
          <dashboard-card
            v-for="anfrage in anfragen"
            :key="anfrage.id"
            :title="anfrage.form"
            :content="anfrage.durchmesser"
            :link="`/anfrage/${anfrage.id}`"
          ></dashboard-card>
          <router-link
            class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center"
            to="/anfrage/neu"
          >
            Neue Anfrage
          </router-link>
        </dashboard-table>
      </dashboard-element>

      <dashboard-element>
        <dashboard-head title="Aufgaben" />
        <dashboard-table class="p-3">
          <dashboard-card
            title="Hier steht ein Titel"
            content="Beschreibung oder dergleichen..."
            link="/aufgabe/2"
          />
          <dashboard-card
            title="Hier steht was anderes"
            content="Beschreibung oder dergleichen..."
          />
          <dashboard-card
            title="Hier steht ein Titel"
            content="Beschreibung oder dergleichen..."
          />
          <dashboard-card
            title="Hier steht ein Titel"
            content="Beschreibung oder dergleichen..."
          />
          <router-link
            class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center"
            to="/aufgabe/neu"
          >
            Neue Aufgabe
          </router-link>
        </dashboard-table>
      </dashboard-element>

      <auftraege />

      <dashboard-element>
        <dashboard-head title="Projekte" />
        <dashboard-table class="p-3">
          <dashboard-card
            title="Hier steht ein Titel"
            content="Beschreibung oder dergleichen..."
            link="/aufgabe/2"
          />
          <dashboard-card
            title="Hier steht was anderes"
            content="Beschreibung oder dergleichen..."
          />
          <dashboard-card
            title="Hier steht ein Titel"
            content="Beschreibung oder dergleichen..."
          />
          <dashboard-card
            title="Hier steht ein Titel"
            content="Beschreibung oder dergleichen..."
          />
          <router-link
            class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center"
            to="/projekt/neu"
          >
            Neue Projekte
          </router-link>
        </dashboard-table>
      </dashboard-element>
    </div>
  </div>
</template>

<script>
import DashboardCard from "../components/dashboard/DashboardCard.vue";
import DashboardElement from "../components/dashboard/DashboardElement.vue";
import DashboardHead from "../components/dashboard/DashboardHead.vue";
import DashboardTable from "../components/dashboard/DashboardTable.vue";
import QueryFile from "../components/ProduktionsAuftraege.vue";
import Auftraege from "../components/Auftraege.vue";

export default {
  components: {
    DashboardCard,
    DashboardTable,
    DashboardHead,
    DashboardElement,
    QueryFile,
    Auftraege
  },
  name: "Dashboard",
  methods: {
    createProduct() {
      console.log("NEW PRODUCT NOW PLZZZ");
    }
  },
  computed: {
    auftraege() {
      return this.$store.state.main.auftraege;
    },
    anfragen() {
      return this.$store.state.anfragen.requests;
    }
  }
};
</script>
