<template>
  <div>
    <dashboard-element>
      <dashboard-head title="Produktionsaufträge" link="/pa" />
      <dashboard-table class="p-3">
        <div v-if="$apollo.queries.produktionsauftra_ge.loading">
          Lade Daten...
        </div>
        <dashboard-card
          v-else
          v-for="pa in produktionsauftra_ge"
          :key="pa.AuftragsNr"
          :title="pa.zuBelegNr"
          :content="pa.Artikelcode"
          :link="`/pa/${pa.AuftragsNr}`"
        />
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DashboardElement from "./dashboard/DashboardElement.vue";
import DashboardHead from "./dashboard/DashboardHead.vue";
import DashboardCard from "./dashboard/DashboardCard.vue";
import DashboardTable from "./dashboard/DashboardTable.vue";

export const GET_MY_DATA = gql`
  query Produktionsauftraege {
    produktionsauftra_ge(
      limit: 5
      where: {
        MengeNochOffen: { _nlike: "0,0" }
        _and: { MengeNochOffen: { _nlike: "" } }
      }
    ) {
      Artikelcode
      AuftragsNr
      MengeNochOffen
      MengeBearbeitet
      zuBelegNr
      zuBelegart
    }
  }
`;

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardCard,
    DashboardTable
  },
  apollo: {
    produktionsauftra_ge: {
      query: GET_MY_DATA,
      error(error) {
        this.error = JSON.stringify(error.message);
      }
    }
  }
};
</script>

<style></style>
